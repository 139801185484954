#loading-container {
    background-color: rgba(255,255,255,0.6);
    width: calc(100vw - 110px);
    height: calc(100vh - 170px); 
    z-index: 1;
    position: fixed
}

#loading-container .fpds-ProgressSpinner {
    position: absolute;
    left: 47%;
    top: 30%;
}